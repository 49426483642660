import "./style.css";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Inicio",
    link: "/",
  },
  {
    _id: 1002,
    title: "Catálogo",
    link: "/catalogo",
  },
  {
    _id: 1003,
    title: "Botines",
    link: "/catalogo",
  },
  {
    _id: 1004,
    title: "Camisetas",
    link: "/catalogo",
  },
  {
    _id: 1005,
    title: "Indumentaria",
    link: "/catalogo",
  },
  {
    _id: 1006,
    title: "Zapatillas",
    link: "/catalogo",
  },
  {
    _id: 1009,
    title: "Accesorios",
    link: "/catalogo",
  },
  {
    _id: 1007,
    title: "Encargo",
    link: "/encargo",
  },
  {
    _id: 1008,
    title: "Ayuda",
    link: "/ayuda",
  },
];
export const allSizes = [
  {
    _id: 9001,
    title: "39",
  },

  {
    _id: 9003,
    title: "40",
  },

  {
    _id: 9005,
    title: "41",
  },

  {
    _id: 9007,
    title: "42",
  },

  {
    _id: 9009,
    title: "43",
  },

  {
    _id: 9011,
    title: "44",
  },

  {
    _id: 90013,
    title: "45",
  },
  {
    _id: 9014,
    title: "S",
  },
  {
    _id: 9015,
    title: "M",
  },
  {
    _id: 9016,
    title: "L",
  },
  {
    _id: 9017,
    title: "XL",
  },
  {
    _id: 9018,
    title: "XXL",
  },
  {
    _id: 9019,
    title: "39/40",
  },
  {
    _id: 9020,
    title: "41/42",
  },
  {
    _id: 9021,
    title: "43/44",
  },
]

export const allSubcategories = [
  {
    _id: 10006,
    title: "Futbol 5",
  },
  {
    _id: 10007,
    title: "Futbol 11",
  },
  {
    _id: 10008,
    title: "24/25",
  },
  {
    _id: 10009,
    title: "Retro",
  },
  {
    _id: 10010,
    title: "Hombre",
  },
  {
    _id: 10011,
    title: "Mujer",
  },
  {
    _id: 10012,
    title: "Campera",
  },
  {
    _id: 10013,
    title: "Buzo",
  },
  {
    _id: 10014,
    title: "Chaleco",
  },
  {
    _id: 10015,
    title: "Conjunto",
  },
  {
    _id: 10016,
    title: "Mochila",
  },
  {
    _id: 10017,
    title: "Medias",
  },
  {
    _id: 10018,
    title: "Guantes",
  },
  {
    _id: 10019,
    title: "Canilleras",
  },
  {
    _id: 10020,
    title: "Termo",
  },
];

export const allBrands = [
  {
    _id: 900,
    title: "Nike",
  },
  {
    _id: 901,
    title: "Adidas",
  },
  {
    _id: 902,
    title: "Puma",
  },
];

export const allCategories = [
  {
    _id: 9006,
    title: "Botines",
  },
  {
    _id: 9009,
    title: "Camisetas",
  },

  {
    _id: 9007,
    title: "Accesorios",
  },
  {
    _id: 9005,
    title: "Indumentaria",
  },
  {
    _id: 9004,
    title: "Zapatillas",
  },
];
export const allTags = [
  {
    _id: 2000,
    title: "Deportivo",
  },
  {
    _id: 2001,
    title: "Urbano",
  },
];

export const botinesSizes = [
  { size: "39", stock: 0, sold: 0 },
  { size: "40", stock: 0, sold: 0 },
  { size: "41", stock: 0, sold: 0 },
  { size: "42", stock: 0, sold: 0 },
  { size: "43", stock: 0, sold: 0 },
  { size: "44", stock: 0, sold: 0 },
  { size: "45", stock: 0, sold: 0 },
];
export const camisetasSizes = [
  { size: "S", stock: 0, sold: 0 },
  { size: "M", stock: 0, sold: 0 },
  { size: "L", stock: 0, sold: 0 },
  { size: "XL", stock: 0, sold: 0 },
  { size: "XXL", stock: 0, sold: 0 },
];
export const mediasSizes = [
  { size: "39-40", stock: 0, sold: 0 },
  { size: "41-42", stock: 0, sold: 0 },
  { size: "43-44", stock: 0, sold: 0 },
];
export const zapatillasMujerSizes = [
  { size: "34", stock: 0, sold: 0 },
  { size: "35", stock: 0, sold: 0 },
  { size: "36", stock: 0, sold: 0 },
  { size: "37", stock: 0, sold: 0 },
  { size: "38", stock: 0, sold: 0 },
];

export const mochilaSizes = [{ size: "Mochila", stock: 0, sold: 0 }];

export const guantesSizes = [
  { size: "8", stock: 0, sold: 0 },
  { size: "9", stock: 0, sold: 0 },
  { size: "10", stock: 0, sold: 0 },
];

export const canillerasSizes = [{ size: "Canilleras", stock: 0, sold: 0 }];

export const termoSizes = [{ size: "Termo", stock: 0, sold: 0 }];

// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const tarjetas = [
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/amex@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/visa@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/mastercard@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/diners@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/ar/tarjeta-shopping@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/ar/tarjeta-naranja@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/ar/cencosud@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/ar/cabal@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/ar/argencard@2x.png"
];

export const otherPaymentMethods = [
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/rapipago@2x.png",
  "https://d26lpennugtm8s.cloudfront.net/assets/common/img/logos/payment/new_logos_payment/pagofacil@2x.png",
]

export const provinces = [
  { provinceCode: 'B', name: 'Buenos Aires' },
  { provinceCode: 'K', name: 'Catamarca' },
  { provinceCode: 'H', name: 'Chaco' },
  { provinceCode: 'U', name: 'Chubut' },
  { provinceCode: 'C', name: 'CABA' },
  { provinceCode: 'X', name: 'Córdoba' },
  { provinceCode: 'W', name: 'Corrientes' },
  { provinceCode: 'E', name: 'Entre Ríos' },
  { provinceCode: 'P', name: 'Formosa' },
  { provinceCode: 'J', name: 'San Juan' },
  { provinceCode: 'Y', name: 'Jujuy' },
  { provinceCode: 'L', name: 'La Pampa' },
  { provinceCode: 'F', name: 'La Rioja' },
  { provinceCode: 'M', name: 'Mendoza' },
  { provinceCode: 'N', name: 'Misiones' },
  { provinceCode: 'Q', name: 'Neuquén' },
  { provinceCode: 'R', name: 'Río Negro' },
  { provinceCode: 'A', name: 'Salta' },
  { provinceCode: 'Z', name: 'Santa Cruz' },
  { provinceCode: 'S', name: 'Santa Fe' },
  { provinceCode: 'D', name: 'San Luis' },
  { provinceCode: 'G', name: 'Santiago del Estero' },
  { provinceCode: 'V', name: 'Tierra del Fuego' },
  { provinceCode: 'T', name: 'Tucumán' }
];









